<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="warning">
          <template slot="header">
            <div class="card-icon">
              <router-link to="/mosaico/datasets/list/teresina/neighborhoods">
                <div class="md-icon">
                  <i class="far fa-map"></i>
                </div>
              </router-link>
            </div>
            <p class="category">Fonte</p>
            <router-link to="/mosaico/datasets/list/teresina/neighborhoods">
              <h3 class="title">
                Bairros Teresina
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Atualizado em:
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon">
              <router-link to="/mosaico/maps/map/brasil">
                <md-icon>public</md-icon>
              </router-link>
            </div>
            <p class="category">Fonte</p>
            <router-link to="/mosaico/maps/map/brasil">
              <h3 class="title">
                Brasil IBGE
              </h3>
            </router-link>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Atualizado em:
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard
  },
  data() {
    return {};
  },
  props: {
    brasilIo: {
      type: String,
      default: require("@/assets/img/logo-brasil-io.png")
    }
  }
};
</script>
<style lang="scss" scoped></style>
